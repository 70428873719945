<template>
  <courseware-form
    :is-version-detail="pageState.action === 'version-detail'"
    :title="pageState.title"
    v-model:current="pageState.current"
    :readOnly="pageState.readOnly"
    @onSave="handleSave"
    @onBeforeGoBack="handleGoBack"
    :isOnPPT="true"
  />
  <div>
    <ppt-editor
      :resource="pageState.current.url"
      :pptName="pageState.current.name"
      :type="pageState.readOnly ? 'preview' : 'edit'"
      resourceType="official"
      :width="'100%'"
      :height="'80vh'"
      :pptStyleProps="{ margin: '20px 0' }"
      :autoHideMenu="true"
      @onSave="handleSavePPT"
    />
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { reactive, onBeforeMount, computed, createVNode, onMounted } from 'vue'
import CoursewareForm from '@/views/courseware/components/CoursewareForm'
import PptEditor from '@/components/ppt-editor/PptEditor.vue'
import { eventCenter, event } from '@evideo/frontend-utils'
import config from '@/config'
import {
  createCourseware,
  getCoursewareInfo,
  getCoursewareVersionInfo,
  editCourseware,
  setCoursewareTags
} from '@/services/courseware'
import { message, Modal } from 'ant-design-vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'

export default {
  name: 'Detail',
  components: {
    CoursewareForm,
    PptEditor
  },
  beforeRouteLeave(to, from, next) {
    eventCenter.send(event.COMMON_UNLOAD_MICRO_APP, {
      app: config.pptist.name
    })
    setTimeout(() => {
      next()
    }, 0)
  },
  setup() {
    // const pageTypeMap = ['edit', 'detail', 'version-detail']
    const route = useRoute()
    console.log('edit', route.params.action, route.params.id) // 0 为创建

    const pageState = reactive({
      loading: false,
      title: '新建课件',
      action: route.params.action,
      readOnly: route.params.action !== 'edit', // 是为预览， 否为编辑
      id: parseInt(route.params.id),
      current: {}
    })

    // 初始化
    if (pageState.action === 'edit') {
      if (pageState.id) {
        pageState.title = '编辑课件'
      } else {
        pageState.title = '新建课件'
        pageState.current.url = ''
      }
    } else {
      pageState.title = '查看课件'
    }

    const queryParams = computed(() => {
      const params = {
        author_type: 'mohuan',
        relate_courseware_ids: pageState.current.relate_courseware_ids,
        name: pageState.current.name,
        type: pageState.current.type,
        size: pageState.current.size,
        url: pageState.current.url,
        md5: pageState.current.md5,
        thumbnail_images: pageState.current.thumbnail_images,
        share_mode: pageState.current.share_mode
      }
      if (
        !pageState.current.book_section_id ||
        pageState.current.book_section_id.length === 0
      ) {
        params.book_section_id = 0
        params.book_id = 0
      } else if (pageState.current.book_section_id.length === 1) {
        params.book_id = pageState.current.book_section_id[0]
        params.book_section_id = 0
      } else {
        params.book_id = pageState.current.book_section_id[0]
        params.book_section_id =
          pageState.current.book_section_id[
            pageState.current.book_section_id.length - 1
          ]
      }
      return params
    })

    const saveCourseware = async () => {
      try {
        let coursewareId
        if (pageState.id) {
          await editCourseware(pageState.id, queryParams.value)
          coursewareId = pageState.id
        } else {
          const { id } = await createCourseware(queryParams.value)
          coursewareId = id
        }
        await setCoursewareTags(coursewareId, { tags: pageState.current.tags })
        message.success({
          key: 'save',
          content: '保存成功'
        })
      } catch (e) {
        console.error(e)
        message.error({
          key: 'save',
          content: '保存失败'
        })
        throw e
      }
    }

    const handleSavePPT = async (data, callback) => {
      const { url, md5 = '', size = 0, previewImages = [] } = data
      pageState.current.url = url
      pageState.current.size = size
      pageState.current.md5 = md5
      pageState.current.type = 'ppt'
      pageState.current.thumbnail_images = previewImages

      const relate_coursewares = JSON.parse(
        sessionStorage.getItem('relate_coursewares')
      )
      const delete_data = JSON.parse(sessionStorage.getItem('delete_data'))
      if (relate_coursewares) {
        // 以添加关联为最后操作的保存课件
        if (!pageState.id) {
          // 新建ppt/说课/教案的情况
          const relate_courseware_ids = relate_coursewares.map(item => item.id)
          pageState.current.relate_courseware_ids = relate_courseware_ids
        } else if (
          pageState.current.relate_coursewares &&
          pageState.current.type !== 'ppt'
        ) {
          // 编辑说课/教案的情况
          pageState.current.relate_courseware_ids = pageState.current.relate_coursewares.map(
            item => item.id
          )
          const relate_courseware_ids = relate_coursewares.map(item => item.id)
          pageState.current.relate_courseware_ids = pageState.current.relate_courseware_ids.concat(
            relate_courseware_ids
          )
        } else {
          // 关联课件后编辑ppt的情况
          pageState.current.relate_courseware_ids = pageState.current.relate_coursewares.map(
            item => item.id
          )
        }
      } else if (delete_data) {
        // 以取消关联为最后操作的保存课件
        pageState.current.relate_courseware_ids = delete_data
      } else if (pageState.id) {
        // 不关联课件编辑ppt
        pageState.current.relate_courseware_ids = pageState.current.relate_coursewares.map(
          item => item.id
        )
      }
      if (pageState.id) {
        await getInfo(true)
      }

      const notEmptyCheckArr = {
        url: '文件不能为空',
        book_id: '教材不能为空',
        name: '名称不能为空'
      }
      let finish = false
      for (const k in notEmptyCheckArr) {
        if (!queryParams.value[k]) {
          message.error(notEmptyCheckArr[k])
          finish = true
          break
        }
      }
      if (finish) {
        callback.reply({ errorcode: 1, message: '保存失败' })
        return
      }

      try {
        await saveCourseware(url) // 这里是根据业务实际上的保存逻辑
        // 执行回调函数，参数中带上保存结果, errorcode中0 为成功， 1为失败
        callback.reply({ errorcode: 0, mssage: 'success' })
        // 编辑模式下自动保存模式不进行路由跳转
        if (pageState.current.type === 'ppt' && pageState.id) {
          return
        }
        history.go(-1)
      } catch (error) {
        console.error(error)
        // 保存失败了也要通知
        callback.reply({ errorcode: 1, message: '保存失败' })
      }
    }

    const handleSave = () => {
      // PPTIST_SAVE_PPT
      eventCenter.send(event.PPTIST_SAVE_PPT)
      pageState.loading = true
      // message.loading({
      //   key: 'save',
      //   content: '正在保存',
      //   duration: 0
      // })
    }

    const getInfo = async (onlyUpdateRelate = false) => {
      console.time('PPTIST_LOAD_PPT之前耗时')
      if (!pageState.id) {
        return
      }
      const getInfoService =
        pageState.action === 'version-detail'
          ? getCoursewareVersionInfo
          : getCoursewareInfo
      if (onlyUpdateRelate) {
        const res = await getInfoService(pageState.id)
        const nowRelateCoursewareIds = res.relate_coursewares.map(i => i.id)
        pageState.current.relate_courseware_ids = nowRelateCoursewareIds
        queryParams.value.relate_courseware_ids = nowRelateCoursewareIds
        return
      }
      pageState.current = await getInfoService(pageState.id)
      console.log(
        '获取最开始关联的课件详情',
        pageState.current.relate_coursewares
      )
      const sectionsId = pageState.current.book_section_id ?? 0
      const bookId = pageState.current.book_id ?? 0
      pageState.current.tags = pageState.current.tags.map(i => i.tag)
      pageState.current.bookShowName = pageState.current.book?.name ?? ''
      pageState.current.book_section_id = [bookId]
      if (pageState.current.book?.name && pageState.current.section?.name) {
        pageState.current.bookShowName += '-' + pageState.current.section.name
        pageState.current.book_section_id = [bookId, sectionsId]
      }
    }

    const checkBeforeLeave = next => {
      eventCenter.send(event.PPTIST_IS_MODIFIED, null, ({ isModified }) => {
        if (isModified) {
          Modal.confirm({
            title: '有尚未保存的ppt内容，确定要离开吗?',
            icon: createVNode(ExclamationCircleOutlined),
            onOk() {
              next()
            }
          })
        } else {
          next()
        }
      })
    }

    const handleGoBack = () => {
      checkBeforeLeave(() => history.go(-1))
      sessionStorage.clear()
    }

    onBeforeMount(async () => {
      await getInfo()
    })

    onMounted(() => {
      sessionStorage.clear()
    })

    return {
      pageState,
      history,
      route,
      handleSave,
      handleSavePPT,
      handleGoBack
    }
  }
}
</script>

<style scoped></style>
